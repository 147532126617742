import loadable from '../../utilities/loadable'

export const ChatWithUs = loadable(() => import('./ChatWithUs'))
export const DisplayInCartProductDetail = loadable(() =>
  import('./DisplayInCartProductDetail')
)
export const DisplayProductDetail = loadable(() =>
  import('./DisplayProductDetail')
)
export const DisplayDealsBulletinProductDetail = loadable(() =>
  import('./DisplayDealsBulletinProductDetail')
)
export const GiveUsACall = loadable(() => import('./GiveUsACall'))
export const HelpCard = loadable(() => import('./HelpCard'))
export const ImagesCarousel = loadable(() => import('./ImagesCarousel'))
export const MobileComingSoon = loadable(() => import('./MobileComingSoon'))
export const MonthlySpend = loadable(() => import('./MonthlySpend'))
export const MultiSelect = loadable(() => import('./MultiSelect'))
export const NabpBadge = loadable(() => import('./NabpBadge'))
export const PayOffline = loadable(() => import('./PayOffline'))
export const PromoModifiersMessage = loadable(() =>
  import('./PromoModifiersMessage')
)
export const PromoModifiersRequiredQtyMessage = loadable(() =>
  import('./PromoModifiersRequiredQtyMessage')
)
export const PromoModifiersTooltip = loadable(() =>
  import('./PromoModifiersTooltip')
)
export const PartOfPromotionBundleIndicator = loadable(() =>
  import('./PartOfPromotionBundleIndicator')
)
export const RewardsExpiryAlert = loadable(() => import('./RewardsExpiryAlert'))
export const ShippingToggleButton = loadable(() =>
  import('./ShippingToggleButton')
)
export const UserAvatar = loadable(() => import('./UserAvatar'))
export const VawdStatus = loadable(() => import('./VawdStatus'))
export const VideoModal = loadable(() => import('./VideoModal'))

export const InfoModal = loadable(() => import('./QuickInfoModal/InfoModal'))
export const SplashScreen = loadable(() =>
  import('./QuickInfoModal/SplashScreen')
)

export const ProductSearchHistory = loadable(() =>
  import('./SearchHistory/ProductSearchHistory')
)

export const OpenCartShippingMessage = loadable(() =>
  import('./OpenCartShippingMessage')
)

export const CcCashBackTooltip = loadable(() => import('./CcCashBackTooltip'))
export const CcCashBackModal = loadable(() => import('./CcCashBackModal'))

export const LowestPriceNabp = loadable(() => import('./LowestPriceNabp'))

export const AutocompletePurchasedProductsField = loadable(() => import('./AutocompletePurchasedProductsField'))
